
import Vue from "vue";
import { required, email } from "vuelidate/lib/validators";

export default Vue.extend({
    name: "sendAcvitation",
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            modalId: "activation",
            form: {
                companyName: "",
                email: "",
            },
        };
    },
    methods: {
        sendActivation() {
            this.$emit("sendActivation", this.form);
        },
    },
    validations() {
        return {
            form: {
                email: {
                    email,
                },
                companyName: {
                    required,
                },
            },
        };
    },
});
