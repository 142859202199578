var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "w-100",
    attrs: {
      "align-h": "center"
    }
  }, [_c('b-col', {
    staticClass: "mt-auto",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column justify-content-center align-items-center mb-5"
  }, [_c('p', {
    staticClass: "h2 font-weight-bold text-black"
  }, [_vm._v(_vm._s(_vm.loading ? 'Verifying' : 'Completed'))]), _c('p', [_vm._v("Take a leap of faith, success awaits every step")])])]), _c('b-col', {
    staticClass: "mb-auto",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-center align-items-center mt-2"
  }, [_c('ba-button', {
    staticClass: "text-black",
    attrs: {
      "text": "Login",
      "disabled": _vm.$v.$invalid,
      "loading": _vm.loading,
      "variant": "warning"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.replace({
          name: _vm.AUTH.LOGIN.NAME
        });
      }
    }
  })], 1)])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }