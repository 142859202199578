
import Vue from "vue";
import { mapActions } from "vuex";
import { required, requiredIf, sameAs, email } from "vuelidate/lib/validators";
import { AUTH } from "../../routes/endpoints";
import ActivationModal from "./modals/activation.vue";

export default Vue.extend({
    components: {
        ActivationModal,
    },
    data() {
        return {
            loading: false,
            loadingModal: false,
            accountCreated: false,
            isPasswordVisible: false,
            isConfirmPasswordVisible: false,
            form: {
                companyName: "",
                companyRCNumber: "",
                officialMail: "",
                registrarFirstName: "",
                registrarLastName: "",
                registrarPosition: "",
                positionOthers: "",
                registrarMail: "",
                registrarContactNo: "",
                password: "",
                confirmPassword: "",
                terms: false,
            },
            AUTH,
            positions: [
                "Company Secretary",
                "Acting Company Secretary",
                "Company Secretarial Staff",
                "Secretarial Support Staff",
                "Compliance Officer",
                "Governance Consultant",
                "Legal Adviser",
                "Legal Officer",
                "Legal Support Staff",
                "Others",
            ],
        };
    },
    methods: {
        ...mapActions("auth", ["signUp", "postActivation"]),
        goTo({ name }: { name: string }) {
            this.$router.push({ name });
        },
        async signUpUser() {
            this.loading = true;

            const {
                companyName,
                companyRCNumber,
                // password,
                // confirmPassword,
                officialMail,
                registrarContactNo,
                registrarFirstName,
                registrarLastName,
                registrarMail,
                registrarPosition,
                positionOthers,
            } = this.form;

            try {
                const payload = {
                    companyName,
                    companyRCNumber,
                    // confirmPassword,
                    officialMail,
                    // password,
                    registrarContactNo,
                    registrarFirstName,
                    registrarLastName,
                    registrarMail,
                    registrarPosition: positionOthers || registrarPosition,
                };

                const res = await this.signUp(payload);

                if (res.status === 200) {
                    this.accountCreated = true;
                } else {
                    this.form.password = "";
                    this.form.confirmPassword = "";
                }
                this.loading = false;
            } catch (error: unknown) {
                this.loading = false;
            }
        },
        async resendActivation() {
            this.$bvModal.show("modal-activation");
        },
        async sendActivation(payload: { name: string; email: string }) {
            try {
                this.loadingModal = true;

                const res = await this.postActivation(payload);

                if (res.status === 200) {
                    this.$bvToast.toast(res.data.message, {
                        title: "Success!",
                        variant: "success",
                        solid: true,
                    });
                    this.$bvModal.hide("modal-activation");
                }
                this.loadingModal = false;
            } catch (error: unknown) {
                this.loadingModal = false;
            }
        },
    },
    validations() {
        return {
            form: {
                companyName: {
                    required,
                },
                companyRCNumber: {
                    required,
                },
                officialMail: {
                    required,
                    email,
                },
                registrarFirstName: {
                    required,
                },
                registrarLastName: {
                    required,
                },
                registrarPosition: {
                    required,
                },
                positionOthers: {
                    required: requiredIf(
                        ({ position }) => position === "Others",
                    ),
                },
                registrarContactNo: {
                    required,
                },
                registrarMail: {
                    required,
                    email,
                },
                // password: {
                //     required,
                // },
                // confirmPassword: {
                //     sameAsPassword: sameAs("password"),
                // },
                terms: {
                    required,
                },
            },
        };
    },
});
