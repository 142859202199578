
import Vue from "vue";
import { mapActions } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import { AUTH, HOME } from "../../routes/endpoints";

export default Vue.extend({
    data() {
        return {
            loading: false,
            isPasswordVisible: false,
            form: {
                email: "",
                password: "",
            },
            AUTH,
        };
    },
    methods: {
        ...mapActions("auth", ["login"]),
        ...mapActions("reports", ["fetchReportList"]),
        goTo({ name }: { name: string }) {
            this.$router.push({ name });
        },
        async loginUser() {
            try {
                this.loading = true;

                const payload = {
                    userName: this.form.email,
                    password: this.form.password,
                };

                const res = await this.login(payload);

                if (res.status === 200) {
                    this.$bvToast.toast(`Login Successful, Welcome!`, {
                        title: "Success!",
                        variant: "success",
                        solid: true,
                    });
                    this.fetchReports();
                    const { next } = this.$route.query;
                    if (next != null && next !== "")
                        this.$router.replace({ path: next as string });
                    else this.$router.replace({ name: HOME.INDEX.NAME });
                }
                this.loading = false;
            } catch (error: unknown) {
                this.loading = false;
            }
        },
        async fetchReports() {
            try {
                await this.fetchReportList();
            } catch (error) {
                //
            } finally {
                //
            }
        },
    },
    validations() {
        return {
            form: {
                email: {
                    required,
                    // email,
                },
                password: {
                    required,
                },
            },
        };
    },
});
