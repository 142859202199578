var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "w-100",
    attrs: {
      "align-h": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_vm._v(" Don't have an account? "), _c('div', {
    staticClass: "ml-2 text-blue-90 signup--text pointer",
    on: {
      "click": function ($event) {
        return _vm.goTo({
          name: _vm.AUTH.SIGN_UP.NAME
        });
      }
    }
  }, [_vm._v(" Sign Up ")])])])]), _c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('b-row', {
    attrs: {
      "align-h": "center"
    }
  }, [_c('b-col', {
    staticClass: "mt-auto",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "mb-5 header__text"
  }, [_c('h2', {
    staticClass: "text-black-100"
  }, [_vm._v(" Forgot password ")]), _c('p', {
    staticClass: "text-gray-900"
  }, [_vm._v(" Enter your User ID to get a reset link in your registered email. ")])])]), _c('b-col', {
    staticClass: "mb-auto",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-text-field', {
    attrs: {
      "required": "",
      "label": "User ID",
      "placeholder": "eg. FRC 0001"
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.email.$touch();
      }
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  }), _vm.$v.form.email.$error ? _c('div', {
    staticClass: "error"
  }, [!_vm.$v.form.email.required ? _c('div', [_vm._v(" User ID is required ")]) : _vm._e()]) : _vm._e()], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center mt-2"
  }, [_c('ba-button', {
    staticClass: "text-black",
    attrs: {
      "text": "Reset",
      "disabled": _vm.$v.$invalid,
      "loading": _vm.loading,
      "variant": "warning"
    },
    on: {
      "click": _vm.resetPassword
    }
  }), _c('div', {
    staticClass: "text-blue-90 signup--text pointer",
    on: {
      "click": function ($event) {
        return _vm.goTo({
          name: _vm.AUTH.LOGIN.NAME
        });
      }
    }
  }, [_vm._v(" Log In → ")])], 1)])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }