
import Vue from "vue";
import { HELP } from "@/app/routes/endpoints";

export default Vue.extend({
    data() {
        return {
            HELP
        };
    },
    methods: {
        goTo({ name }: { name: string }) {
            const routeData = this.$router.resolve({ name });
            window.open(routeData.href, '_blank');
        },
    }
});
