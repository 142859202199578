var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "h-100",
    attrs: {
      "align-h": "center"
    }
  }, [_c('b-col', {
    staticClass: "pb-5"
  }, [_vm.accountCreated ? _c('b-row', {
    staticClass: "w-100 h-100",
    attrs: {
      "align-h": "center",
      "align-v": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-row', {
    attrs: {
      "align-h": "center"
    }
  }, [_c('b-col', {
    staticClass: "mt-auto",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column justify-content-center align-items-center mb-5"
  }, [_c('p', {
    staticClass: "h2 font-weight-bold text-black"
  }, [_vm._v(" Account Created ")]), _c('p', {
    staticClass: "text-center"
  }, [_vm._v(" Your account has been successfully created, kindly login to your email " + _vm._s(_vm.form.registrarMail) + " to activate it. ")]), _c('ba-button', {
    staticClass: "text-black",
    attrs: {
      "text": "Back to Login",
      "variant": "warning"
    },
    on: {
      "click": function ($event) {
        return _vm.goTo({
          name: _vm.AUTH.LOGIN.NAME
        });
      }
    }
  })], 1)])], 1)], 1)], 1) : _c('b-row', {
    attrs: {
      "align-h": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-end mb-5"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_vm._v(" Already have an account? "), _c('div', {
    staticClass: "ml-2 text-blue-90 signup--text pointer",
    on: {
      "click": function ($event) {
        return _vm.goTo({
          name: _vm.AUTH.LOGIN.NAME
        });
      }
    }
  }, [_vm._v(" Login ")])])])]), _c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('b-row', {
    attrs: {
      "align-h": "center"
    }
  }, [_c('b-col', {
    staticClass: "mt-auto",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "mb-5 header__text"
  }, [_c('h2', {
    staticClass: "text-black-100"
  }, [_vm._v(" Get Started ")]), _c('p', {
    staticClass: "text-gray-900"
  }, [_vm._v(" Complete the form to sign up. ")])])]), _c('b-col', {
    staticClass: "mb-auto",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-row', {
    attrs: {
      "align-h": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-text-field', {
    attrs: {
      "required": "",
      "label": "Company name",
      "placeholder": "Company name"
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.companyName.$touch();
      }
    },
    model: {
      value: _vm.form.companyName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "companyName", $$v);
      },
      expression: "form.companyName"
    }
  }), _vm.$v.form.companyName.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" Company Name is required ")]) : _vm._e()], 1), _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-text-field', {
    attrs: {
      "required": "",
      "label": "Company RC Number",
      "placeholder": "Company RC Number"
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.companyRCNumber.$touch();
      }
    },
    model: {
      value: _vm.form.companyRCNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "companyRCNumber", $$v);
      },
      expression: "form.companyRCNumber"
    }
  }), _vm.$v.form.companyRCNumber.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" Company RC Number is required ")]) : _vm._e()], 1), _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-text-field', {
    attrs: {
      "required": "",
      "label": "Key contact email",
      "placeholder": "e.g test@nccg.gov.ng"
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.officialMail.$touch();
      }
    },
    model: {
      value: _vm.form.officialMail,
      callback: function ($$v) {
        _vm.$set(_vm.form, "officialMail", $$v);
      },
      expression: "form.officialMail"
    }
  }), _vm.$v.form.officialMail.$error ? _c('div', {
    staticClass: "error"
  }, [!_vm.$v.form.officialMail.required ? _c('div', [_vm._v(" Email is required ")]) : _vm._e(), !_vm.$v.form.officialMail.email ? _c('div', [_vm._v(" Email is not valid ")]) : _vm._e()]) : _vm._e()], 1), _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-text-field', {
    attrs: {
      "required": "",
      "label": "First name",
      "placeholder": "First name"
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.registrarFirstName.$touch();
      }
    },
    model: {
      value: _vm.form.registrarFirstName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "registrarFirstName", $$v);
      },
      expression: "form.registrarFirstName"
    }
  }), _vm.$v.form.registrarFirstName.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" First Name is required ")]) : _vm._e()], 1), _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-text-field', {
    attrs: {
      "required": "",
      "label": "Last name",
      "placeholder": "Last name"
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.registrarLastName.$touch();
      }
    },
    model: {
      value: _vm.form.registrarLastName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "registrarLastName", $$v);
      },
      expression: "form.registrarLastName"
    }
  }), _vm.$v.form.registrarLastName.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" Last Name is required ")]) : _vm._e()], 1), _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-dropdown', {
    attrs: {
      "required": "",
      "label": "Position",
      "options": _vm.positions
    },
    on: {
      "hide": function ($event) {
        return _vm.$v.form.registrarPosition.$touch();
      }
    },
    model: {
      value: _vm.form.registrarPosition,
      callback: function ($$v) {
        _vm.$set(_vm.form, "registrarPosition", $$v);
      },
      expression: "form.registrarPosition"
    }
  }), _vm.$v.form.registrarPosition.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" Position is required ")]) : _vm._e()], 1), _vm.form.registrarPosition === 'Others' ? _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-text-field', {
    attrs: {
      "required": "",
      "label": "Position (Others)",
      "placeholder": "Position (Others)"
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.positionOthers.$touch();
      }
    },
    model: {
      value: _vm.form.positionOthers,
      callback: function ($$v) {
        _vm.$set(_vm.form, "positionOthers", $$v);
      },
      expression: "form.positionOthers"
    }
  }), _vm.$v.form.positionOthers.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" Position (Others) is required ")]) : _vm._e()], 1) : _vm._e(), _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-text-field', {
    attrs: {
      "required": "",
      "label": "Email address",
      "placeholder": "e.g test@nccg.gov.ng"
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.registrarMail.$touch();
      }
    },
    model: {
      value: _vm.form.registrarMail,
      callback: function ($$v) {
        _vm.$set(_vm.form, "registrarMail", $$v);
      },
      expression: "form.registrarMail"
    }
  }), _vm.$v.form.registrarMail.$error ? _c('div', {
    staticClass: "error"
  }, [!_vm.$v.form.registrarMail.required ? _c('div', [_vm._v(" Email is required ")]) : _vm._e(), !_vm.$v.form.registrarMail.email ? _c('div', [_vm._v(" Email is not valid ")]) : _vm._e()]) : _vm._e()], 1), _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-phone-field', {
    staticClass: "text-black-90",
    attrs: {
      "label": "Phone number",
      "classText": "white",
      "required": "",
      "placeholder": "Phone number"
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.registrarContactNo.$touch();
      }
    },
    model: {
      value: _vm.form.registrarContactNo,
      callback: function ($$v) {
        _vm.$set(_vm.form, "registrarContactNo", $$v);
      },
      expression: "form.registrarContactNo"
    }
  }), _vm.$v.form.registrarContactNo.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" Phone is required ")]) : _vm._e()], 1), _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-checkbox', {
    staticClass: "mb-3 mt-0 term--text text-black-100",
    on: {
      "input": function ($event) {
        return _vm.$v.form.terms.$touch();
      }
    },
    model: {
      value: _vm.form.terms,
      callback: function ($$v) {
        _vm.$set(_vm.form, "terms", $$v);
      },
      expression: "form.terms"
    }
  }, [_vm._v(" I agree to the "), _c('a', {
    staticClass: "text-yellow-130",
    attrs: {
      "target": "_blank",
      "href": "https://www.bodadmin.com/terms-of-use"
    }
  }, [_vm._v(" Terms & Conditions of Use, ")]), _c('a', {
    staticClass: "text-yellow-130",
    attrs: {
      "target": "_blank",
      "href": "https://www.bodadmin.com/privacy-policy"
    }
  }, [_vm._v(" Privacy policies")]), _vm._v(" and "), _c('a', {
    staticClass: "text-yellow-130",
    attrs: {
      "target": "_blank",
      "href": "https://www.bodadmin.com/learn-more/reporting-portal"
    }
  }, [_vm._v(" Product guides. ")])]), _vm.$v.form.terms.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" You must agree with the terms and policy before you can create an account ")]) : _vm._e()], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center mt-4"
  }, [_c('ba-button', {
    staticClass: "text-black",
    attrs: {
      "text": "Create Account",
      "variant": "warning",
      "disabled": _vm.$v.$invalid || !_vm.form.terms,
      "loading": _vm.loading
    },
    on: {
      "click": _vm.signUpUser
    }
  })], 1)])], 1)], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('activation-modal', {
    attrs: {
      "loading": _vm.loadingModal
    },
    on: {
      "sendActivation": _vm.sendActivation
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }