
import Vue from "vue";
import { mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import { AUTH } from "../../routes/endpoints";

export default Vue.extend({
    data() {
        return {
            loading: false,
            form: {
                email: "",
            },
            AUTH,
        };
    },
    methods: {
        ...mapActions("auth", ["forgotPassword"]),
        goTo({ name }: { name: string }) {
            this.$router.push({ name });
        },
        async resetPassword() {
            try {
                this.loading = true;

                const payload = {
                    username: this.form.email,
                };

                const res = await this.forgotPassword(payload);

                if (res.status === 200) {
                    this.$bvToast.toast(res.data.message, {
                        title: "Success!",
                        variant: "success",
                        solid: true,
                    });
                }
                this.loading = false;
                this.form.email = "";
                this.$v.$reset();
            } catch (error: unknown) {
                this.loading = false;
            }
        },
    },
    validations() {
        return {
            form: {
                email: {
                    required,
                },
            },
        };
    },
});
