var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "auth__wrapper overflow-hidden"
  }, [_c('div', {
    staticClass: "bg-black-100 side__wrapper d-flex"
  }, [_c('div', {
    staticClass: "inner__card"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', [_c('ba-base-icon', {
    staticClass: "mr-2",
    attrs: {
      "name": "ba-frc-logo-new"
    }
  })], 1), _vm._m(0)]), _vm._m(1), _c('div', {
    staticClass: "mt-3 d-flex flex-column play__video--wrapper"
  }, [_c('ba-base-icon', {
    attrs: {
      "name": "ba-bg-help-img"
    }
  }), _c('p', {
    staticClass: "text-left play__video--text"
  }, [_vm._v("Learn more about how to use and navigate the system, and find answers to FAQs in the help centre.")]), _c('div', {
    staticClass: "d-flex"
  }, [_c('p', {
    staticClass: "help-text pointer mr-4 mb-0",
    on: {
      "click": function ($event) {
        return _vm.goTo({
          name: _vm.HELP.INDEX.NAME
        });
      }
    }
  }, [_vm._v("Help centre "), _c('ba-base-icon', {
    staticClass: "ml-1",
    attrs: {
      "name": "ba-open-icon"
    }
  })], 1), _c('a', {
    staticClass: "product-guide-btn",
    attrs: {
      "href": "https://www.bodadmin.com/learn-more/reporting-portal",
      "target": "_blank"
    }
  }, [_vm._v("Product guide "), _c('ba-base-icon', {
    attrs: {
      "name": "ba-open-icon"
    }
  })], 1)])], 1)]), _c('div', {
    staticClass: "mt-auto"
  }, [_c('ba-bodadmin-logo')], 1)]), _c('div', {
    staticClass: "login__wrapper overflow-auto w-100 d-flex"
  }, [_c('router-view')], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-lg frc"
  }, [_vm._v(" FINANCIAL REPORTING "), _c('br'), _vm._v("COUNCIL OF NIGERIA ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-5"
  }, [_c('p', {
    staticClass: "welcome--text"
  }, [_vm._v("Welcome to the")]), _c('h3', {
    staticClass: "text-white welcome--subtext"
  }, [_c('span', {
    staticClass: "text-yellow"
  }, [_vm._v("Reporting Portal")]), _c('span', {
    staticClass: "text-gray-primary"
  }, [_vm._v(" of the")]), _vm._v(" Financial Reporting Council of Nigeria (FRC)")])]);

}]

export { render, staticRenderFns }