
import Vue from "vue";
import PaymentService from "@/api/payment-service";
import ReportService from "@/api/report-service";
import { HOME } from "../../routes/endpoints";

export default Vue.extend({
    methods: {
        async queryTransactionUsingRRR() {
            try {
                const urlParams = new URLSearchParams(window.location.search);
                const rrr = urlParams.get("RRR");

                if (rrr) {
                    const res = await PaymentService.queryTransactionUsingRRR(
                        rrr as string,
                    );
                    if (res.data.status === "01") {
                        await ReportService.submitReport(res.data.reportId);
                    }
                    this.$bvToast.toast(res.data.message, {
                        title: "Success!",
                        variant: "success",
                        solid: true,
                    });
                } else {
                    this.$bvToast.toast("A valid RRR was not provided", {
                        title: "Something went wrong",
                        variant: "danger",
                        solid: true,
                    });
                }
            } catch (error) {
                //
            }
        },
        goToReports() {
            this.$router.replace({
                name: HOME.REPORTS.NAME,
            });
        },
    },
    mounted() {
        this.queryTransactionUsingRRR();
    },
});
