
import Vue from "vue";
import { mapActions } from "vuex";
import { required, sameAs } from "vuelidate/lib/validators";
import { AUTH } from "../../routes/endpoints";

export default Vue.extend({
    data() {
        return {
            token: "",
            email: "",
            loading: false,
            isPasswordVisible: false,
            isPasswordConfirmVisible: false,
            form: {
                password: "",
                confirmPassword: "",
            },
            AUTH,
            isPasswordReset: false
        };
    },
    methods: {
        ...mapActions("auth", ["passwordReset"]),
        goTo({ name }: { name: string }) {
            this.$router.push({ name });
        },
        async resetPassword() {
            try {
                this.loading = true;

                const payload = {
                    password: this.form.password,
                    confirmPassword: this.form.confirmPassword,
                    email: this.email,
                    token: this.token,
                };

                const res = await this.passwordReset(payload);

                if (res.status === 200) {
                    this.$bvToast.toast(
                        `Password reset Successful, Please login!`,
                        {
                            title: "Success!",
                            variant: "success",
                            solid: true,
                        },
                    );

                    this.form.password = "";
                    this.form.confirmPassword = "";
                    this.$v.$reset();
                    this.isPasswordReset =  true
                }
                this.loading = false;
            } catch (error: unknown) {
                this.loading = false;
            }
        },
    },
    mounted() {
        this.token = this.$route.query.Token as string;
        this.email = this.$route.query.Email as string;
    },
    validations() {
        return {
            form: {
                password: {
                    required,
                },
                confirmPassword: {
                    required,
                    sameAsPassword: sameAs("password"),
                },
            },
        };
    },
});
