var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "h-100 d-flex"
  }, [_c('b-row', {
    attrs: {
      "align-h": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_vm._v(" Don't have an account? "), _c('div', {
    staticClass: "ml-2 text-blue-90 signup--text pointer",
    on: {
      "click": function ($event) {
        return _vm.goTo({
          name: _vm.AUTH.SIGN_UP.NAME
        });
      }
    }
  }, [_vm._v(" Sign Up ")])])])]), _c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('b-row', {
    attrs: {
      "align-h": "left"
    }
  }, [_c('b-col', {
    staticClass: "mt-auto",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column mb-3 header__text"
  }, [_c('h2', {
    staticClass: "text-black-100"
  }, [_vm._v(" Welcome ")]), _c('p', [_vm._v(" Login to access the reporting portal ")])])]), _c('b-col', {
    staticClass: "mb-auto",
    attrs: {
      "cols": "12"
    }
  }, [_c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.loginUser.apply(null, arguments);
      }
    }
  }, [_c('b-row', {
    attrs: {
      "align-h": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-text-field', {
    attrs: {
      "required": "",
      "label": "User ID",
      "placeholder": "eg. FRC 0001"
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.email.$touch();
      }
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  }), _vm.$v.form.email.$error ? _c('div', {
    staticClass: "error"
  }, [!_vm.$v.form.email.required ? _c('div', [_vm._v(" User ID is required ")]) : _vm._e()]) : _vm._e()], 1), _c('b-col', {
    staticClass: "mt-3 input__relative",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_c('ba-text-field', {
    attrs: {
      "required": "",
      "label": "Password",
      "type": _vm.isPasswordVisible ? 'text' : 'password',
      "placeholder": "********"
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.password.$touch();
      }
    },
    model: {
      value: _vm.form.password,
      callback: function ($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  }), _c('div', {
    class: !_vm.$v.form.password.$error ? 'append--icon' : 'error-append-icon'
  }, [_c('b-icon', {
    staticClass: "mx-2 pointer",
    attrs: {
      "font-scale": "1.3",
      "icon": !_vm.isPasswordVisible ? 'eye-fill' : 'eye-slash-fill'
    },
    on: {
      "click": function ($event) {
        _vm.isPasswordVisible = !_vm.isPasswordVisible;
      }
    }
  })], 1)], 1), _vm.$v.form.password.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" Password is required ")]) : _vm._e()])], 1), _c('b-row', [_c('b-col', {
    staticClass: "mt-5",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center mt-2"
  }, [_c('ba-button', {
    staticClass: "text-black",
    attrs: {
      "text": "Login",
      "disabled": _vm.$v.$invalid,
      "loading": _vm.loading,
      "variant": "warning",
      "type": "submit"
    }
  }), _c('div', {
    staticClass: "ml-2 text-blue-90 pointer",
    on: {
      "click": function ($event) {
        return _vm.goTo({
          name: _vm.AUTH.FORGOT_PASSWORD.NAME
        });
      }
    }
  }, [_vm._v(" Forgot Password? ")])], 1)])], 1)], 1)])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }