var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-base-dialog', {
    attrs: {
      "id": _vm.modalId,
      "size": "md",
      "title": "Resend Email Activation",
      "description": "",
      "custom-content-class": "overflow-scroll "
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-text-field', {
    attrs: {
      "label": "Alterate Email Address (Optional)",
      "placeholder": "eg. JohnDoe@gmail.com"
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.email.$touch();
      }
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  }), _vm.$v.form.email.$error ? _c('div', {
    staticClass: "error"
  }, [!_vm.$v.form.email.required ? _c('div', [_vm._v("Email is required")]) : _vm._e(), !_vm.$v.form.email.email ? _c('div', [_vm._v("Email is not valid")]) : _vm._e()]) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-text-field', {
    attrs: {
      "label": "Company Name",
      "placeholder": "eg. Dangote Industries"
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.companyName.$touch();
      }
    },
    model: {
      value: _vm.form.companyName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "companyName", $$v);
      },
      expression: "form.companyName"
    }
  }), _vm.$v.form.companyName.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" Name is required ")]) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-end mt-3"
  }, [_c('ba-button', {
    staticClass: "ml-4 text-black",
    attrs: {
      "text": "Send",
      "variant": "warning",
      "loading": _vm.loading,
      "disabled": _vm.$v.$invalid
    },
    on: {
      "click": _vm.sendActivation
    }
  })], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }