import http from "@/utils/http";
import resolveRequestError from "@/utils/errorHandler";
import { PAYMENT } from "./endpoints";

export default class PaymentService {
    static async generateRRR(payload: any): Promise<any> {
        try {
            const data = await http.post(PAYMENT.GENERATERRR, payload);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async generateInvoice(reportId: string, payload: any): Promise<any> {
        try {
            const data = await http.post(
                PAYMENT.GENERATEINVOICE(reportId),
                payload,
            );
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async generateInvoiceAndPay(reportId: string, payload: any): Promise<any> {
        try {
            const data = await http.post(
                PAYMENT.GENERATEANDPAYINVOICE(reportId),
                payload,
            );
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async queryTransactionUsingRRR(rrr: string): Promise<any> {
        try {
            const data = await http.get(PAYMENT.QUERYTRANSACTIONUSINGRRR(rrr));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }
}
