var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "h-100 d-flex"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-row', {
    staticClass: "h-100",
    attrs: {
      "align-h": "center",
      "align-v": "center"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('h3', [_vm._v(" Your payment is currently being processed. Click 'View Reports' to proceed. ")]), _c('ba-button', {
    staticClass: "text-black mt-4",
    attrs: {
      "text": "View Reports",
      "variant": "warning"
    },
    on: {
      "click": _vm.goToReports
    }
  })], 1)])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }