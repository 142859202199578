var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "h-100 d-flex"
  }, [_c('b-row', {
    attrs: {
      "align-h": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_vm._v(" Don't have an account? "), _c('div', {
    staticClass: "ml-2 text-blue-90 signup--text pointer",
    on: {
      "click": function ($event) {
        return _vm.goTo({
          name: _vm.AUTH.SIGN_UP.NAME
        });
      }
    }
  }, [_vm._v(" Sign Up ")])])])]), _vm.isPasswordReset ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-row', {
    attrs: {
      "align-h": "center"
    }
  }, [_c('b-col', {
    staticClass: "mt-auto",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column justify-content-center align-items-center mb-5"
  }, [_c('p', {
    staticClass: "h2 font-weight-bold text-black"
  }, [_vm._v(" Reset Password ")]), _c('p', {
    staticClass: "text-center"
  }, [_vm._v(" Your password has been reset successfully, kindly login to continue. ")]), _c('ba-button', {
    staticClass: "text-black",
    attrs: {
      "text": "Back to Login",
      "variant": "warning"
    },
    on: {
      "click": function ($event) {
        return _vm.goTo({
          name: _vm.AUTH.LOGIN.NAME
        });
      }
    }
  })], 1)])], 1)], 1) : _vm._e(), _vm.token && !_vm.isPasswordReset ? _c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('b-row', {
    attrs: {
      "align-h": "center"
    }
  }, [_c('b-col', {
    staticClass: "mt-auto",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "mb-5 header__text"
  }, [_c('h2', {
    staticClass: "text-black-100"
  }, [_vm._v(" Create new password ")]), _c('p', {
    staticClass: "text-gray-900"
  }, [_vm._v(" Enter and confirm your new password ")])])]), _c('b-col', {
    staticClass: "mb-auto",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-row', {
    attrs: {
      "align-h": "center"
    }
  }, [_c('b-col', {
    staticClass: "input__relative",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_c('ba-text-field', {
    attrs: {
      "required": "",
      "type": _vm.isPasswordVisible ? 'text' : 'password',
      "label": "New Password",
      "placeholder": "**********"
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.password.$touch();
      }
    },
    model: {
      value: _vm.form.password,
      callback: function ($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  }), _c('div', {
    class: !_vm.$v.form.password.$error ? 'append--icon' : 'error-append-icon'
  }, [_c('b-icon', {
    staticClass: "mx-2 pointer",
    attrs: {
      "font-scale": "1.3",
      "icon": !_vm.isPasswordVisible ? 'eye-fill' : 'eye-slash-fill'
    },
    on: {
      "click": function ($event) {
        _vm.isPasswordVisible = !_vm.isPasswordVisible;
      }
    }
  })], 1)], 1), _vm.$v.form.password.$error ? _c('div', {
    staticClass: "error"
  }, [!_vm.$v.form.password.required ? _c('div', [_vm._v(" New Password is required ")]) : _vm._e()]) : _vm._e()]), _c('b-col', {
    staticClass: "mt-3 input__relative",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_c('ba-text-field', {
    attrs: {
      "required": "",
      "type": _vm.isPasswordConfirmVisible ? 'text' : 'password',
      "label": "Confirm Password",
      "placeholder": "**********"
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.confirmPassword.$touch();
      }
    },
    model: {
      value: _vm.form.confirmPassword,
      callback: function ($$v) {
        _vm.$set(_vm.form, "confirmPassword", $$v);
      },
      expression: "form.confirmPassword"
    }
  }), _c('div', {
    class: !_vm.$v.form.confirmPassword.$error ? 'append--icon' : 'error-append-icon'
  }, [_c('b-icon', {
    staticClass: "mx-2 pointer",
    attrs: {
      "font-scale": "1.3",
      "icon": !_vm.isPasswordConfirmVisible ? 'eye-fill' : 'eye-slash-fill'
    },
    on: {
      "click": function ($event) {
        _vm.isPasswordConfirmVisible = !_vm.isPasswordConfirmVisible;
      }
    }
  })], 1)], 1), _vm.$v.form.confirmPassword.$error ? _c('div', {
    staticClass: "error"
  }, [!_vm.$v.form.confirmPassword.required ? _c('div', [_vm._v(" Confirm Password is required ")]) : _vm._e(), _vm.$v.form.confirmPassword.required && !_vm.$v.form.confirmPassword.sameAsPassword ? _c('div', [_vm._v(" Passwords do not match ")]) : _vm._e()]) : _vm._e()])], 1), _c('b-row', [_c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center mt-2"
  }, [_c('ba-button', {
    staticClass: "text-black",
    attrs: {
      "text": "Reset",
      "disabled": _vm.$v.$invalid,
      "loading": _vm.loading,
      "variant": "warning"
    },
    on: {
      "click": _vm.resetPassword
    }
  })], 1)])], 1)], 1)], 1)], 1) : _vm._e(), !_vm.token && !_vm.isPasswordReset ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "h1 font-weight-bold text-black text-center"
  }, [_vm._v(" Oops! Token provided is invalid ")])]) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }